<template>
  <v-container style="max-width: 1300px">
    <v-row class="px-4 pb-5 mt-0 headline">
      {{ $t('calendarSettings.calendarPermissionSettings') }}
    </v-row>

    <v-card>
      <v-tabs v-model="tab" right>
        <v-tab>
          {{ $t('calendarSettings.users') }}
        </v-tab>
        <v-tab>
          {{ $t('calendarSettings.departments') }}
        </v-tab>
      </v-tabs>
      <v-card-text class="pa-0">
        <v-data-table
          v-model="selectedUsers"
          :headers="computedHeaders"
          :search="search"
          :items="owners"
          item-key="uid"
          :sort-by="!tab ? 'uid' : ''"
          class="elevation-1"
          :custom-filter="customFilter"
          show-select
        >
          <template #top>
            <v-toolbar flat>
              <v-btn
                v-if="selectedUsers.length > 0"
                color="primary"
                @click="editCalendarPermissionsForSelectedUsers"
              >
                {{ $t('calendarSettings.editPermissions') }}
              </v-btn>
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="getSearchLabel"
                single-line
                hide-details
                style="max-width: 500px"
                class="pr-4"
              />
            </v-toolbar>
          </template>
          <template #[`item.default_calendar.my_department`]="{ item }">
            <v-chip :color="getColor(item.default_calendar.my_department)" dark>
              {{
                $t(
                  `calendarSettingChips.${item.default_calendar.my_department}`,
                )
              }}
            </v-chip>
          </template>
          <template #[`item.default_calendar.other_department`]="{ item }">
            <v-chip
              :color="getColor(item.default_calendar.other_department)"
              dark
            >
              {{
                $t(
                  `calendarSettingChips.${item.default_calendar.other_department}`,
                )
              }}
            </v-chip>
          </template>
          <template #[`item.permission_actions`]="{ item }">
            <v-icon small @click="editUserCalendarPermissions(item)">
              mdi-pencil
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <select-user-calendar-setting
      ref="calendarSetting"
      :is-editing="isEditing"
      :calendar-settings="calendarSettings"
      @updated="updateCalendarPermissions"
    />
  </v-container>
</template>

<script>
import { toAscii } from 'japanese-string-utils';
import SelectUserCalendarSetting from '../components/SelectUserCalendarSetting.vue';

export default {
  components: {
    SelectUserCalendarSetting,
  },
  data() {
    return {
      tab: 0,
      dialog: false,
      isEditing: false,
      calendarSettings: null,
      search: '',
      selectedUsers: [],
      selectedUser: null,
      headers: [
        {
          text: this.$t('calendarSettings.name'),
          width: '20%',
          value: 'name',
        },
        {
          text: this.$t('calendarSettings.email'),
          value: 'email',
          width: '25%',
        },
        {
          text: this.$t('calendarSettings.department'),
          value: 'department.name',
          width: '15%',
        },
        {
          text: this.$t('calendarSettings.permissionMyDept'),
          value: 'default_calendar.my_department',
          width: '14%',
        },
        {
          text: this.$t('calendarSettings.permissionOtherDept'),
          value: 'default_calendar.other_department',
          width: '14%',
        },
        {
          text: this.$t('calendarSettings.editPermissions'),
          sortable: false,
          value: 'permission_actions',
          width: '12%',
          align: 'center',
        },
      ],
      users: [],
      departments: [],
      teams: [],
    };
  },
  computed: {
    computedHeaders() {
      let tempHeaders = this.headers;

      if (this.tab === 0) {
        tempHeaders = this.getHeadersForUsersTab();
      } else {
        tempHeaders = this.getHeadersForDepartmentsTab();
      }

      if (this.selectedUsers.length > 0) {
        tempHeaders = tempHeaders.filter(
          (header) => header.value !== 'permission_actions',
        );
      }
      return tempHeaders;
    },
    owners() {
      switch (this.tab) {
        case 0:
          return this.getUsersData();
        default:
          return this.getDepartmentsData();
      }
    },
    getSearchLabel() {
      switch (this.tab) {
        case 0:
          return this.$t('calendarSettings.searchUsers');
        case 1:
          return this.$t('calendarSettings.searchDepartments');

        default:
          return this.$t('calendarSettings.search');
      }
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.populateUsers();
      this.populateDepartments();
    },
    customFilter(value, search, item) {
      if (value === undefined) {
        return false;
      }
      const itemTextHalfWidth = toAscii(value);
      const queryTextHalfWidth = toAscii(search);
      if (!itemTextHalfWidth || !queryTextHalfWidth) {
        return false;
      }
      return (
        itemTextHalfWidth
          .toLowerCase()
          .indexOf(queryTextHalfWidth.toLowerCase()) > -1
      );
    },
    async populateUsers() {
      const { data } = await axios.get('api/users');
      this.users = data;
    },
    async populateDepartments() {
      const { data } = await axios.get('api/departments');
      this.departments = data;
    },

    getHeadersForUsersTab() {
      const tempHeaders = this.headers;

      tempHeaders[0].text = this.$t('calendarSettings.name');
      tempHeaders[0].width = '20%';

      return tempHeaders;
    },

    getHeadersForDepartmentsTab() {
      let tempHeaders = this.headers;

      tempHeaders = tempHeaders.filter(
        (header) =>
          !(header.value === 'department.name' || header.value === 'email'),
      );
      tempHeaders[0].text = this.$t('calendarSettings.departmentName');
      tempHeaders[0].width = '50%';

      return tempHeaders;
    },

    getUsersData() {
      this.users.forEach((user) => {
        user.uid = `user${user.id}`;
      });
      return this.users;
    },
    getDepartmentsData() {
      this.departments.forEach((department) => {
        department.uid = `dep${department.id}`;
        [department.default_calendar] = department.calendars;
      });
      return this.departments;
    },
    getColor(value) {
      switch (value) {
        case Calendar.PRIVATE:
          return 'red';
        case Calendar.READ_STATUS:
          return 'orange';
        case Calendar.READ_DETAILS:
          return 'blue';
        case Calendar.PUBLIC:
          return 'green';
        default:
          return 'green';
      }
    },
    editUserCalendarPermissions(user) {
      this.selectedUser = user;
      this.$refs.calendarSetting.openDialog();
      const calendarSettings = {
        my_department: user.default_calendar.my_department,
        other_department: user.default_calendar.other_department,
      };
      this.isEditing = true;
      this.calendarSettings = calendarSettings;
    },
    updateCalendarPermissions(form) {
      if (this.selectedUser != null && this.selectedUsers.length == 0) {
        this.updateCalendarPermissionsForUser(form);
      } else {
        this.updateCalendarPermissionsForSelectedUsers(form);
      }
    },
    updateCalendarPermissionsForUser(form) {
      axios
        .put(
          `api/calendar/${this.selectedUser.default_calendar.id}/sharing-settings`,
          {
            my_department: form.my_department,
            other_department: form.other_department,
          },
        )
        .then(() => {
          this.initialize();
        });
    },
    editCalendarPermissionsForSelectedUsers() {
      this.$refs.calendarSetting.openDialog();
      const calendarSettings = {
        my_department: Calendar.PUBLIC,
        other_department: Calendar.PRIVATE,
      };
      this.isEditing = true;
      this.calendarSettings = calendarSettings;
    },
    async updateCalendarPermissionsForSelectedUsers(form) {
      this.selectedUsers.forEach((user) => {
        axios
          .put(`api/calendar/${user.default_calendar.id}/sharing-settings`, {
            my_department: form.my_department,
            other_department: form.other_department,
          })
          .then(() => {
            this.initialize();
          })
          .catch(() => {});
      });
    },
  },
};
</script>
