<template>
  <v-dialog v-model="isDialogOpen" persistent width="550px">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col class="pa-0">
            {{ $t('adminSettings.sortEmployees') + ' : ' }}
            {{ selected !== null ? selected.name : '' }}
          </v-col>
          <v-col cols="1" class="pa-0 pr-1">
            <v-btn icon @click="isDialogOpen = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text v-if="!isSelectorDisabled">
        {{ $t('adminSettings.chooseDepartment') }}
        <department-selector
          ref="departmentSelector"
          @change="getDepartmentUsersForSort"
        />
      </v-card-text>
      <v-card-text v-if="selected !== null">
        {{ $t('adminSettings.sortEmployeesMessage') }}
        <sorting-list v-model="users" class="mt-2" style="width: 600px" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" outlined @click="isDialogOpen = false">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          :disabled="selected === null"
          @click="postSortedMembers"
        >
          {{ $t('common.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import SortingList from './SortingList.vue';
import DepartmentSelector from './DepartmentSelectorForAdmin.vue';

export default {
  components: { SortingList, DepartmentSelector },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    initialSelection: {
      type: Object,
      default: null,
    },
    isSelectorDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selected: null,
    users: [],
  }),
  computed: {
    ...mapGetters(['departmentUsers', 'teamUsers']),
    isDialogOpen: {
      get() {
        return this.isOpen;
      },
      set(value) {
        if (!value) {
          this.closeDialog();
        }
      },
    },
  },
  watch: {
    isOpen() {
      if (this.isOpen && this.initialSelection !== null) {
        this.getTeamUsersForSort(this.initialSelection);
      }
    },
  },
  methods: {
    getDefaultData() {
      this.selected = null;
      this.users = [];
    },
    getDepartmentUsersForSort(department) {
      const users = this.departmentUsers(department.id);
      this.users = this.processUsers(users);
      this.selected = department;
    },
    getTeamUsersForSort(team) {
      const users = this.teamUsers(team.id);
      this.users = this.processUsers(users);
      this.selected = team;
    },
    processUsers(users) {
      return users.map((user) => ({ id: user.id, name: user.name }));
    },
    async postSortedMembers() {
      try {
        await axios.post('/api/sorted-users/', {
          users: this.users,
          sortedForType: this.selected.type === 'team' ? 'Team' : 'Department',
          sortedForId: this.selected.id,
        });
        await this.$store.dispatch('fetchAndSetGroupsUsers');
        this.$store.dispatch('openSnackbar', {
          color: 'success',
          message: this.$t('common.addedUserSorting'),
        });
      } catch (error) {
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('common.somethingWentWrong'),
        });
      }
      this.isDialogOpen = false;
    },
    closeDialog() {
      this.getDefaultData();
      if (this.$refs.departmentSelector) {
        this.$refs.departmentSelector.resetDepartment();
      }
      this.$emit('close');
    },
  },
};
</script>
