<template>
  <v-container style="max-width: 700px">
    <v-row class="px-4 mt-0 pb-5 headline">
      {{ $t('eventTypeCrud.eventTypes') }}
      <v-spacer />
      <v-btn
        class="mr-1"
        color="primary"
        elevation="0"
        @click="addEditDialogOpen(null)"
      >
        <v-icon>mdi-plus</v-icon>
        {{ $t('eventTypeCrud.add') }}
      </v-btn>
      <v-btn color="primary" elevation="0" @click="openSortEventTypesDialog">
        <v-icon>mdi-sort</v-icon>
        {{ $t('eventTypeCrud.sort') }}
      </v-btn>
    </v-row>
    <v-row>
      <v-card width="100%">
        <v-card-title class="pb-0 pt-2">
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-data-table :headers="headers" :items="eventTypes">
            <template #[`item.actions`]="{ item }">
              <v-row>
                <v-col cols="12" sm="1">
                  <v-icon small @click="addEditDialogOpen(item)"
                    >mdi-pencil</v-icon
                  >
                </v-col>
                <v-col col="12" sm="1">
                  <v-icon small @click="deleteEventType(item)"
                    >mdi-delete</v-icon
                  >
                </v-col>
              </v-row>
            </template>
            <template #[`item.color`]="{ item }">
              <v-btn :color="item.color" elevation="0" block></v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-row>
    <confirm ref="confirm" />
    <add-edit-event-type
      :initial-event-type="eventType"
      :dialog="addEditDialog"
      @add-edit="getAllEventTypes(), closeDialog()"
      @close="closeDialog()"
    />
    <SortingEventTypes v-model="isSortEventTypesDialogOpen" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import Confirm from '../../components/Confirm.vue';
import AddEditEventType from './components/AddEditEventType.vue';
import SortingEventTypes from './components/SortingEventTypes.vue';

export default {
  components: {
    Confirm,
    AddEditEventType,
    SortingEventTypes,
  },
  data: () => ({
    addEditDialog: false,
    eventType: {
      id: 0,
      name: '',
      color: '',
    },
    isSortEventTypesDialogOpen: false,
    headers: [
      {
        text: 'Color',
        align: 'center',
        value: 'color',
        width: '30%',
      },
      { text: 'Event Type', align: 'center', value: 'name' },
      {
        text: 'Actions',
        align: 'center',
        value: 'actions',
        width: '15%',
      },
    ],
  }),
  computed: {
    ...mapGetters({ eventTypes: 'eventTypesWithoutNull' }),
  },
  methods: {
    async getAllEventTypes() {
      this.$store.dispatch('fetchEventTypes');
    },
    addEditDialogOpen(item) {
      if (item != null) {
        this.eventType.id = item.id;
        this.eventType.name = item.name;
        this.eventType.color = item.color;
      } else {
        this.eventType.id = 0;
        this.eventType.name = '';
        this.eventType.color = 'primary';
      }
      this.addEditDialog = true;
    },
    async deleteEventType(eventType) {
      const confirmation = await this.$refs.confirm.open(
        this.$t('common.confirmDelete'),
        this.$t('eventType.confirmDelete'),
        {
          color: 'red',
          width: 400,
        },
      );
      if (confirmation === false) {
        return;
      }
      try {
        await axios.delete(`/api/event-types/${eventType.id}`);
        this.$emit('delete');
        this.getAllEventTypes();
        this.$store.dispatch('openSnackbar', {
          color: 'success',
          message: this.$t('eventTypeCrud.deleted'),
        });
      } catch {
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('common.somethingWentWrong'),
        });
      }
    },
    closeDialog() {
      this.addEditDialog = false;
    },

    openSortEventTypesDialog() {
      this.isSortEventTypesDialogOpen = true;
    },
  },
};
</script>
