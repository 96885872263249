<template>
  <v-container style="max-width: 1000px">
    <v-row class="px-4 mt-0 pb-5 headline">
      {{ $t('facilityCrud.facilitySettings') }}
      <v-spacer />
      <v-btn
        class="mr-2 white--text"
        color="indigo"
        elevation="0"
        to="facility-type-management"
      >
        <v-icon class="mr-1">mdi-shape-outline</v-icon>
        {{ $t('adminSettings.manageFacilityTypes') }}
      </v-btn>

      <v-btn color="primary" elevation="0" @click="openAddFacilityForm(null)">
        <v-icon>mdi-plus</v-icon>
        {{ $t('facilityCrud.addFacility') }}
      </v-btn>
    </v-row>
    <v-card width="100%">
      <v-card-text>
        <v-data-table :headers="headers" :items="allFacilities">
          <template #[`item.actions`]="{ item }">
            <v-row>
              <v-col sm="1">
                <v-icon small @click="openEditFacilityForm(item)">
                  mdi-pencil
                </v-icon>
              </v-col>
              <v-col sm="1">
                <v-icon small @click="deleteFacility(item.id)">
                  mdi-delete
                </v-icon>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <add-edit-facility ref="addEditFacility" />
    <confirm ref="confirm" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import Confirm from '../../components/Confirm.vue';
import AddEditFacility from './components/AddEditFacility.vue';

export default {
  components: {
    AddEditFacility,
    Confirm,
  },
  computed: {
    ...mapGetters('groups', ['departments']),
    ...mapGetters(['allFacilities', 'facility']),
    headers() {
      return [
        {
          text: this.$t('facilityCrud.facility'),
          value: 'name',
          width: '40%',
          sortable: true,
        },
        {
          text: this.$t('facilityCrud.facilityType'),
          value: 'typeName',
          sortable: true,
        },
        {
          text: this.$t('common.actions'),
          value: 'actions',
          width: '20%',
          sortable: false,
        },
      ];
    },
  },
  methods: {
    openAddFacilityForm() {
      this.$refs.addEditFacility.openAddForm();
    },
    async openEditFacilityForm(item) {
      const details = this.facility(item.id);
      const facility = await this.getFacilityDetails(item.id);
      facility.departments = this.getDepartmentsForFacility(details);
      this.$refs.addEditFacility.openEditForm(facility);
    },
    async getFacilityDetails(itemId) {
      const response = await axios.get(`/api/facility/${itemId}`);
      const facility = {};
      facility.id = response.data.id;
      facility.name = response.data.name;
      facility.typeId = response.data.facility_type_id;
      facility.isVisibleToAllDepartments = false;
      facility.departments = [];
      facility.images = response.data.urls;
      facility.extraInfo = this.getExtraInfo(response.data.extra_info);
      return facility;
    },
    getExtraInfo(extraInfo) {
      if (extraInfo == null) {
        return [];
      }
      const extraInfoArray = [];

      const keys = Object.keys(extraInfo);
      for (let i = 0; i < keys.length; i += 1) {
        if (extraInfo[keys[i]] !== null && extraInfo[keys[i]] !== '') {
          extraInfoArray.push({
            category: keys[i],
            categoryAnswer: extraInfo[keys[i]],
          });
        }
      }
      return extraInfoArray;
    },
    getDepartmentsForFacility(details) {
      if (details.is_visible_to_all_departments) {
        return this.departments.map((department) => department.id);
      }
      return details.departments.map((department) => department.id);
    },
    async deleteFacility(id) {
      const confirmation = await this.$refs.confirm.open(
        this.$t('common.confirmDelete'),
        this.$t('facilityCrud.areYouSureDelete'),
        {
          color: 'red',
          width: 400,
        },
      );
      if (confirmation === false) {
        return;
      }
      try {
        await axios.delete(`/api/facility/${id}`);
        this.$store.dispatch('fetchAndSetAllFacilitiesAndFacilityTypes');
        this.$store.dispatch('openSnackbar', {
          color: 'success',
          message: this.$t('facilityCrud.facilityDeletedSuccessfully'),
        });
      } catch {
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('common.somethingWentWrong'),
        });
      }
    },
  },
};
</script>
