<template>
  <v-dialog v-model="isDialogOpen" persistent width="550px">
    <v-card>
      <v-card-title>
        {{ $t('adminSettings.sortFacilities') }}
        <v-spacer></v-spacer>
        <v-btn icon @click="isDialogOpen = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        {{ $t('adminSettings.sortFacilitiesMessage') }}
        <sorting-list
          v-model="sortingFacilities"
          class="mt-2"
          style="width: 600px"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" outlined @click="isDialogOpen = false">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn color="primary" @click="postSortedFacilities">
          {{ $t('common.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import SortingList from './SortingList.vue';

export default {
  components: { SortingList },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    sortingFacilities: [],
  }),
  computed: {
    ...mapGetters(['allFacilities']),

    isDialogOpen: {
      get() {
        return this.isOpen;
      },
      set(value) {
        if (!value) {
          this.closeDialog();
        }
      },
    },
  },
  watch: {
    isOpen() {
      if (this.isOpen) {
        this.sortingFacilities = [...this.allFacilities];
      }
    },
  },
  methods: {
    async postSortedFacilities() {
      try {
        await axios.patch('/api/facilities/', {
          facilities: this.sortingFacilities,
        });
        this.$store.dispatch('fetchAndSetFacilities');
        this.$store.dispatch('openSnackbar', {
          color: 'success',
          message: this.$t('messages.updateSortingOfFacilities'),
        });
      } catch (error) {
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('common.somethingWentWrong'),
        });
      }
      this.isDialogOpen = false;
    },
    closeDialog() {
      this.$emit('close');
    },
  },
};
</script>
