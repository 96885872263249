<template>
  <v-container :style="{ 'max-width': '900px' }">
    <v-row class="px-4 pb-5 mt-0 headline">
      {{ $t('departmentSendingInformationSetting.title') }}
    </v-row>
    <v-card>
      <v-card-text>
        <v-data-table :headers="headers" :items="tableDisplay">
          <template #[`item.actions`]="{ item }">
            <v-switch
              v-model="item.information_send"
              :label="getLabel(item)"
              @change="departmentInformationUpdate(item)"
            />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { getDepartments } from '../../api/departments.api';
import { updateDepartment } from '../../api/adminSettings.api';

export default {
  data: () => ({
    tableDisplay: [],
  }),

  computed: {
    headers() {
      return [
        {
          text: this.$t('departmentSendingInformationSetting.departmentName'),
          value: 'name',
        },
        {
          text: this.$t('departmentSendingInformationSetting.actions'),
          value: 'actions',
          width: '50%',
          align: 'start',
          sortable: false,
        },
      ];
    },
  },

  created() {
    this.getDefaultData();
  },

  methods: {
    getDefaultData() {
      this.getAllDepartments();
    },

    async getAllDepartments() {
      const { data } = await getDepartments();
      this.tableDisplay = [...data];
    },

    async departmentInformationUpdate(item) {
      await updateDepartment(item.id, item.information_send);
    },

    getLabel(item) {
      return item.information_send
        ? this.$t('departmentSendingInformationSetting.canSend')
        : this.$t('departmentSendingInformationSetting.cannotSend');
    },
  },
};
</script>
