<template>
  <div>
    <v-row
      v-for="(category, index) in categoryRows"
      :key="index"
      align="center"
    >
      <v-col cols="12" sm="1">
        <v-btn elevation="0" fab x-small color="red lighten-3">
          <v-icon color="white" @click="removeCategoryRow(index)">
            mdi-minus
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="5">
        <v-text-field
          v-model="category.category"
          :label="$t('facilityCrud.facilityAddCategory')"
          solo
          dense
          hide-details
          @input="sendCategoryValues()"
        />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="category.categoryAnswer"
          :label="$t('facilityCrud.facilityAddCategoryAnswer')"
          solo
          dense
          hide-details
          @input="sendCategoryValues()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-spacer />
      <v-col cols="12" sm="5">
        <v-text-field v-model="remark" hide-details readonly solo dense />
      </v-col>
      <v-col cols="12" sm="6">
        <v-text-field
          v-model="remarkAnswer"
          :label="$t('facilityCrud.facilityAddRemarkAnswer')"
          solo
          dense
          hide-details
          @input="sendCategoryValues()"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    editingExtraInfo: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    categoryRows: [],

    remark: '',
    remarkAnswer: '',
  }),

  watch: {
    editingExtraInfo() {
      this.setDetails();
    },
  },

  created() {
    this.remark = this.$t('facilityCrud.facilityAddRemark');
    this.getDefaultData();
  },

  methods: {
    getDefaultData() {
      this.setDetails();
    },

    setDetails() {
      const { length } = this.editingExtraInfo;
      const categoryRows = [];

      if (length > 0) {
        for (let i = 0; i < length - 1; i += 1) {
          categoryRows.push({
            category: this.editingExtraInfo[i].category,
            categoryAnswer: this.editingExtraInfo[i].categoryAnswer,
          });
        }
        this.remark = this.editingExtraInfo[length - 1].category;
        this.remarkAnswer = this.editingExtraInfo[length - 1].categoryAnswer;
      }

      this.categoryRows = categoryRows;
    },

    addCategoryRow() {
      this.categoryRows.push({
        category: '',
        categoryAnswer: '',
      });
    },

    removeCategoryRow(index) {
      this.categoryRows.splice(index, 1);
      this.sendCategoryValues();
    },

    sendCategoryValues() {
      let extraInfos = [];

      extraInfos.push({
        category: this.remark,
        categoryAnswer: this.remarkAnswer,
      });

      extraInfos = this.categoryRows.concat(extraInfos);

      this.$emit('changed', extraInfos);
    },
  },
};
</script>
