<template>
  <v-dialog v-model="dialog" persistent width="800px">
    <v-card>
      <v-card-title>
        {{
          isEditMode
            ? $t('facilityCrud.editFacility')
            : $t('facilityCrud.addFacility')
        }}
        <v-spacer />
        <v-btn icon color="grey" @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="facility.name"
                :label="$t('facilityCrud.facilityName')"
                :rules="[
                  () =>
                    facility.name.trim() !== '' ||
                    $t('facilityCrud.facilityNameError'),
                ]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="facility.typeId"
                :items="allFacilityTypes"
                item-text="name"
                item-value="id"
                :label="$t('facilityCrud.facilityType')"
                :rules="[() => !!facility.typeId || $t('common.required')]"
              ></v-select>
            </v-col>
          </v-row>
          <department-selector-containing-all-option
            v-model="facility.departments"
            @all="setIsVisibleToAllDepartments"
          />
        </v-form>
        <image-picker
          :editing-images="facility.images"
          @updated-attachments="attachments = $event"
          @deleted-images="deletedImages = $event"
        />
        <v-divider />
        <extra-details
          :editing-extra-info="facility.extraInfo"
          @change="updateExtraInfo"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn outlined @click="dialog = false">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn color="primary" @click="save">{{ $t('common.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

import ImagePicker from './ImagePicker.vue';
import ExtraDetails from './ExtraDetails.vue';
import DepartmentSelectorContainingAllOption from '../../../components/DepartmentSelectorContainingAllOption.vue';

export default {
  components: {
    ImagePicker,
    ExtraDetails,
    DepartmentSelectorContainingAllOption,
  },
  data: () => ({
    dialog: false,
    isEditMode: false,
    facility: {
      id: 0,
      name: '',
      typeId: 0,
      isVisibleToAllDepartments: false,
      departments: [],
      images: [],
      extraInfo: [],
    },
    attachments: [],
    deletedImages: [],
  }),
  computed: {
    ...mapGetters(['allFacilityTypes']),
  },
  methods: {
    openAddForm() {
      this.isEditMode = false;
      this.resetForm();
      this.dialog = true;
    },
    openEditForm(facility) {
      this.isEditMode = true;
      this.resetForm();
      this.dialog = true;
      this.facility = facility;
    },
    updateExtraInfo(extraInfo) {
      this.facility.extraInfo = extraInfo;
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      const formData = this.createFormData();
      this.addOrEditFacility(formData);
    },
    setIsVisibleToAllDepartments(value) {
      this.facility.isVisibleToAllDepartments = value;
    },
    createFormData() {
      const formData = new FormData();
      formData.append('name', this.facility.name);
      formData.append('type', this.facility.typeId);
      formData.append(
        'isVisibleToAllDepartments',
        this.facility.isVisibleToAllDepartments,
      );
      formData.append(
        'departments',
        this.facility.departments.filter((department) => department !== null),
      );
      let j = 0;
      this.attachments.forEach((file) => {
        formData.append(`attachments[${j}]`, file, file.name);
        j += 1;
      });
      formData.append('deletedImages', JSON.stringify(this.deletedImages));
      const infos = {};
      if (this.facility.extraInfo.length !== 0) {
        for (let i = 0; i < this.facility.extraInfo.length; i += 1) {
          const info = this.facility.extraInfo[i];
          if (
            info.categoryAnswer.trim() !== '' &&
            info.category.trim() !== ''
          ) {
            infos[info.category] = info.categoryAnswer;
          } else if (
            info.category === this.$t('facilityCrud.facilityAddRemark')
          ) {
            infos[info.category] = info.categoryAnswer;
          }
        }
      }
      const infoString = JSON.stringify(infos);
      formData.append('extraInfo', infoString);
      return formData;
    },
    async addOrEditFacility(formData) {
      if (this.isEditMode) {
        await this.submitEditForm(formData);
      } else {
        await this.submitAddForm(formData);
      }
      this.$emit('submitted');
      this.dialog = false;
    },
    async submitEditForm(formData) {
      try {
        formData.append('id', this.facility.id);
        await axios.post('/api/facility-update', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.$store.dispatch('openSnackbar', {
          color: 'success',
          message: this.$t('facilityCrud.facilityEditedSuccessfully'),
        });
        this.$store.dispatch('fetchAndSetAllFacilitiesAndFacilityTypes');
      } catch {
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('common.somethingWentWrong'),
        });
      }
    },
    async submitAddForm(formData) {
      try {
        await axios.post('/api/facility', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.$store.dispatch('openSnackbar', {
          color: 'success',
          message: this.$t('facilityCrud.facilityAddedSuccessfully'),
        });
        this.$store.dispatch('fetchAndSetAllFacilitiesAndFacilityTypes');
      } catch {
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('common.somethingWentWrong'),
        });
      }
    },
    resetForm() {
      this.attachments = [];
      this.deletedImages = [];
      this.facility = {
        id: 0,
        name: '',
        typeId: 0,
        images: [],
        isVisibleToAllDepartments: false,
        departments: [],
        extraInfo: [],
      };
      this.$nextTick(() => {
        this.$refs.form.resetValidation();
      });
    },
  },
};
</script>
