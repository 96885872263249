<template>
  <v-card outlined>
    <v-list dense>
      <v-list-item-group color="white">
        <draggable
          :list="items"
          class="list-group"
          ghost-class="ghost"
          @start="isDragging = true"
          @end="
            isDragging = false;
            $emit('input', items);
          "
        >
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            :ripple="false"
            :class="`pa-0`"
            :style="`cursor: ${isDragging ? 'grabbing' : 'grab'}`"
          >
            <v-list-item-content>
              <v-row align="center" class="mx-1 px-2 text-subtitle-1">
                <v-col sm="2" class="pa-0 grey--text text--darken-2">
                  <v-icon>mdi-drag-horizontal-variant</v-icon>
                </v-col>
                <v-col sm="1" class="pa-0 black--text">
                  {{ index + 1 }}
                </v-col>
                <v-col sm="7" class="pa-0 black--text"> {{ item.name }} </v-col>
                <v-col sm="2" class="pa-0" align="center">
                  <v-btn v-if="index !== 0" icon @click="moveUpward(index)">
                    <v-icon> mdi-arrow-up-drop-circle </v-icon>
                  </v-btn>
                  <v-btn
                    v-if="index !== items.length - 1"
                    icon
                    @click="moveDownward(index)"
                  >
                    <v-icon> mdi-arrow-down-drop-circle-outline </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </draggable>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  components: {
    draggable,
  },
  props: {
    value: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    isDragging: false,
  }),
  computed: {
    items: {
      get() {
        return [...this.value];
      },
      set(newVal) {
        this.$emit('input', newVal);
      },
    },
  },
  methods: {
    moveUpward(index) {
      const items = [...this.items];
      const temp = { ...items[index] };
      items[index] = { ...items[index - 1] };
      items[index - 1] = { ...temp };
      this.items = items;
    },
    moveDownward(index) {
      const items = [...this.items];
      const temp = { ...items[index] };
      items[index] = { ...items[index + 1] };
      items[index + 1] = { ...temp };
      this.items = items;
    },
  },
};
</script>

<style scoped lang="scss">
.ghost {
  opacity: 0.5;
  background: #d4eaf5;
}
</style>
