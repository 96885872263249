<template>
  <v-carousel continuous show-arrows-on-hover height="400">
    <v-carousel-item v-for="(image, i) in images" :key="i">
      <v-img max-height="400px" :src="image.url" contain>
        <v-row>
          <v-col class="mt-2 mb-6 pr-6" align="right" justify="center">
            <v-btn
              fab
              color="red lighten-4"
              small
              @click="$emit('deleted-image', i)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-img>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
};
</script>
