<template>
  <v-dialog v-model="dialog" max-width="390">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? $t('holidays.edit') : $t('holidays.add') }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="name"
              :label="$t('holidays.name')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template #activator="{ on }">
                <v-text-field
                  v-model="date"
                  :label="$t('holidays.date')"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                first-day-of-week="1"
                :locale="$i18n.locale"
                :events="getNationalHolidayEventColor"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="black" text @click="close">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn
          color="primary"
          :disabled="isWaitingForResponse"
          :loading="isWaitingForResponse"
          @click="submit"
        >
          {{ $t('common.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import nationalHolidayDatePickerEvent from '../../mixins/nationalHolidayDatePickerEvent';

export default {
  mixins: [nationalHolidayDatePickerEvent],
  data() {
    return {
      dialog: false,
      id: null,
      name: '',
      menu: false,
      date: null,
      isEditing: false,
      isWaitingForResponse: false,
    };
  },
  methods: {
    open(holiday, isEditing = false) {
      this.name = '';

      if (isEditing) {
        this.isEditing = true;
        this.id = holiday.id;
        this.name = holiday.name;
        this.date = holiday.date;
      }

      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    submit() {
      if (this.isEditing) {
        this.submitEdit();
      } else {
        this.submitAdd();
      }
    },
    async submitAdd() {
      this.isWaitingForResponse = true;
      try {
        await axios.post('api/national-holidays', {
          name: this.name,
          date: this.date,
        });
        this.$store.dispatch('openSnackbar', {
          message: this.$t('holidays.created'),
          color: 'success',
        });
        this.$emit('added-holiday');
        this.close();
      } catch {
        this.$store.dispatch('openSnackbar', {
          message: this.$t('common.somethingWentWrong'),
          color: 'error',
        });
      }
      this.isWaitingForResponse = false;
    },
    async submitEdit() {
      this.isWaitingForResponse = true;
      try {
        await axios.patch(`api/national-holidays/${this.id}`, {
          name: this.name,
          date: this.date,
        });
        this.$store.dispatch('openSnackbar', {
          message: this.$t('holidays.updated'),
          color: 'success',
        });
        this.$emit('edited-holiday');
        this.close();
      } catch {
        this.$store.dispatch('openSnackbar', {
          message: this.$t('common.somethingWentWrong'),
          color: 'error',
        });
      }
      this.isWaitingForResponse = false;
    },
  },
};
</script>
