<template>
  <v-autocomplete
    ref="selector"
    v-model="departmentsSelected"
    prepend-icon="mdi-briefcase"
    :items="departments"
    :search-input.sync="search"
    item-text="name"
    item-value="id"
    :label="$t('adminSettings.departmentsThatCanViewFacility')"
    chips
    multiple
    :menu-props="{ auto: true }"
    clearable
    :rules="[() => departmentsSelected.length != 0 || $t('common.required')]"
  >
    <template #selection="{ item, index }">
      <v-chip v-if="isAllDepartmentSelected && index === 0">
        {{ $t('common.all') }}
      </v-chip>
      <v-chip v-if="!isAllDepartmentSelected">
        {{ item.name }}
      </v-chip>
    </template>
    <template #prepend-item>
      <v-list-item ripple @click="toggleAllButton">
        <v-list-item-action>
          <v-icon :color="isAllDepartmentSelected ? 'primary' : ''">
            {{ allCheckboxIconType }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('common.all') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2" />
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    search: null,
    isAllDepartmentSelected: false,
  }),

  computed: {
    ...mapGetters('groups', ['departments']),
    departmentsSelected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    allCheckboxIconType() {
      return this.isAllDepartmentSelected
        ? 'mdi-checkbox-marked'
        : 'mdi-checkbox-blank-outline';
    },
  },

  watch: {
    departmentsSelected() {
      this.search = '';
      this.setIsAllDepartmentSelected();
    },
  },

  created() {
    this.setIsAllDepartmentSelected();
  },
  methods: {
    setIsAllDepartmentSelected() {
      this.isAllDepartmentSelected =
        this.departmentsSelected.length === this.departments.length;
      this.$emit('all', this.isAllDepartmentSelected);
    },
    getAllDepartmentsSelected() {
      return this.departments.map((department) => department.id);
    },
    toggleAllButton() {
      this.isAllDepartmentSelected = !this.isAllDepartmentSelected;
      this.departmentsSelected = this.isAllDepartmentSelected
        ? this.getAllDepartmentsSelected()
        : [];
    },
    validate(value) {
      return this.$refs.selector.validate(value);
    },
    reset() {
      this.$refs.selector.reset();
    },
  },
};
</script>
