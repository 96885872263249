<template>
  <v-expansion-panels>
    <v-expansion-panel elevation="0">
      <v-expansion-panel-header>
        {{ $t('eventTypeCrud.eventTypeColor') }}
        <v-chip :color="color" :text-color="textColor" class="ml-4">
          {{ $t('eventTypeCrud.eventTypePreview') }}
        </v-chip>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div v-for="(colorType, typeIndex) in defaultColors" :key="typeIndex">
          <div class="d-flex justify-space-around flex-wrap">
            <v-icon
              v-for="(colorDisplayed, index) in colorType"
              :key="index"
              :value="colorDisplayed"
              :color="colorDisplayed"
              x-large
              elevation="0"
              @click="setColor(colorDisplayed)"
            >
              mdi-checkbox-blank
            </v-icon>
          </div>
        </div>
        <v-expansion-panels>
          <v-expansion-panel elevation="0">
            <v-expansion-panel-header>
              {{ $t('eventTypeCrud.colorSelector') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content align="center">
              <v-color-picker v-model="color" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: 'primary',
    },
  },

  data: () => ({
    defaultColors: {
      dark: [
        '#B60205',
        '#D93F0B',
        '#FBCA04',
        '#0E8A16',
        '#006B75',
        '#1D76DB',
        '#0052CC',
        '#5319E7',
      ],
      light: [
        '#E99695',
        '#F9D0C4',
        '#FEF2C0',
        '#C2E0C6',
        '#BFDADC',
        '#C5DEF5',
        '#BFD4F2',
        '#D4C5F9',
      ],
    },
  }),
  computed: {
    color: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
        this.$emit('change', value);
      },
    },
    textColor() {
      return this.$root.getTextColor(this.color);
    },
  },
  methods: {
    setColor(color) {
      this.color = color;
    },
  },
};
</script>
