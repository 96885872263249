<template>
  <v-dialog v-model="value" persistent width="550px">
    <v-card>
      <v-card-title>
        {{ $t('eventTypeCrud.sortEventTypes') }}
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        {{ $t('eventTypeCrud.sortEventTypesMessage') }}
        <SortingList
          v-model="sortingEventTypes"
          class="mt-2"
          style="width: 600px"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" outlined @click="close">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn color="primary" @click="submit">
          {{ $t('common.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import SortingList from '../../AdminSettings/components/SortingList.vue';

export default {
  components: {
    SortingList,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      sortingEventTypes: [],
    };
  },

  created() {
    this.fetchEventTypes();
  },

  computed: {
    ...mapGetters({ eventTypes: 'eventTypesWithoutNull' }),
    isOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    value() {
      if (this.value) {
        this.fetchEventTypes();
      }
    },
  },

  methods: {
    async fetchEventTypes() {
      await this.$store.dispatch('fetchEventTypes');
      this.sortingEventTypes = this.eventTypes;
    },

    async submit() {
      try {
        await axios.patch('api/event-types', {
          event_types: this.sortingEventTypes,
        });
        this.$store.dispatch('updateEventTypes', this.sortingEventTypes);
        this.$store.dispatch('openSnackbar', {
          color: 'success',
          message: this.$t('messages.updatedSortingOfEventTypes'),
        });
      } catch ($e) {
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('common.somethingWentWrong'),
        });
      }
      this.close();
    },
    close() {
      this.$emit('input', false);
    },
  },
};
</script>
