<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('calendarPermissions.edit') }}</span>
      </v-card-title>
      <v-card-text>
        <v-container fluid>
          <v-select
            v-model="form.my_department"
            :items="permissions"
            item-text="text"
            item-value="id"
            :label="$t('calendarPermissions.selectSameDept')"
            persistent-hint
            :menu-props="{ bottom: true, offsetY: true }"
          />
          <v-select
            v-model="form.other_department"
            :items="permissions"
            item-text="text"
            item-value="id"
            :label="$t('calendarPermissions.selectOtherDept')"
            persistent-hint
            :menu-props="{ bottom: true, offsetY: true }"
          />
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="closeDialog">
          {{ $t('common.close') }}
        </v-btn>
        <v-btn color="primary" @click="submit">
          {{ $t('common.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
    calendarSettings: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
      permissions: [
        { id: Calendar.PRIVATE, text: this.$t('calendarSettings.private') },
        {
          id: Calendar.READ_STATUS,
          text: this.$t('calendarSettings.readStatus'),
        },
        {
          id: Calendar.READ_DETAILS,
          text: this.$t('calendarSettings.readDetails'),
        },
        { id: Calendar.PUBLIC, text: this.$t('calendarSettings.public') },
      ],
      form: {
        my_department: null,
        other_department: null,
      },
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
      this.$nextTick(() => {
        if (this.isEditing) {
          this.setFormValues(this.calendarSettings);
        }
      });
    },
    closeDialog() {
      this.dialog = false;
    },
    submit() {
      this.$emit('updated', this.form);
      this.closeDialog();
    },
    setFormValues(calendar) {
      this.form.my_department = calendar.my_department;
      this.form.other_department = calendar.other_department;
    },
  },
};
</script>
