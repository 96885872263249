<template>
  <div>
    <v-file-input
      v-model="attachments"
      accept=".png, .jpeg, .jpg"
      small-chips
      multiple
      prepend-icon="mdi-image-multiple"
      :clearable="false"
      :label="$t('facilityCrud.facilityImages')"
      @change="displayAttachedImages"
    />
    <images-display
      v-if="images.length > 0"
      :images="images"
      @deleted-image="deleteImage"
    />
  </div>
</template>

<script>
import ImagesDisplay from './ImagesDisplay.vue';

export default {
  components: {
    ImagesDisplay,
  },
  props: {
    editingImages: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    images: [],
    attachments: [],
    deletedImages: [],
  }),
  watch: {
    editingImages() {
      this.setImageData();
      this.reset();
    },
  },
  created() {
    this.setImageData();
  },
  methods: {
    setImageData() {
      this.images = [...this.editingImages];
      this.removeDeletedImages();
    },
    removeDeletedImages() {
      this.images = this.images.filter((image) => {
        for (let i = 0; i < this.deletedImages.length; i += 1) {
          if (this.deletedImages[i].pseudo_name === image.pseudo_name) {
            return false;
          }
        }
        return true;
      });
    },
    reset() {
      this.attachments = [];
      this.deletedImages = [];
    },
    displayAttachedImages() {
      this.setImageData();
      this.attachments.forEach((attachment) => {
        this.images.push(this.convertToUrl(attachment));
      });
      this.$emit('updated-attachments', this.attachments);
    },
    convertToUrl(attachment) {
      return {
        actual_name: attachment.name,
        pseudo_name: '',
        url: URL.createObjectURL(attachment),
      };
    },
    deleteImage(index) {
      if (this.isImageDeletedFromAlreadyUploadedOnes(index)) {
        this.deletedImages.push(this.images[index]);
        this.$emit('deleted-images', this.deletedImages);
      } else {
        this.attachments.splice(this.getIndexInAttachments(index), 1);
        this.$emit('updated-attachments', this.attachments);
      }
      this.images.splice(index, 1);
    },
    isImageDeletedFromAlreadyUploadedOnes(index) {
      return index < this.editingImages.length - this.deletedImages.length;
    },
    getIndexInAttachments(index) {
      return index - (this.editingImages.length - this.deletedImages.length);
    },
  },
};
</script>
