<template>
  <v-container>
    <v-row class="px-4 pb-4 headline">
      <v-col cols="12" sm="6" :class="{ 'pb-0': $vuetify.breakpoint.xsOnly }">
        {{ $t('team.myTeams') }}
      </v-col>
      <v-col class="d-flex justify-end" cols="12" sm="6">
        <v-btn color="primary" @click="openAddTeamDialog">
          <v-icon left>mdi-plus</v-icon>
          {{ $t('team.add') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="teams"
        :loading="loading"
        :expanded.sync="expanded"
        :mobile-breakpoint="960"
        :loading-text="$t('common.waitMessage')"
      >
        <template #[`item.users`]="{ value }">
          <v-chip
            v-for="(user, i) in value"
            :key="i"
            class="my-1 mr-1 px-2"
            color="rgb(225, 245, 254)"
          >
            {{ user.name }}
            <v-btn
              v-if="user.pivot.is_admin"
              icon
              small
              class="ml-1"
              @click="removeAdmin(user)"
            >
              <v-tooltip bottom :disabled="$vuetify.breakpoint.xsOnly">
                <template #activator="{ on }">
                  <v-icon small v-on="on"> mdi-account-key </v-icon>
                </template>
                {{ $t('team.removeAdmin') }}
              </v-tooltip>
            </v-btn>
            <v-btn v-else icon small class="ml-1" @click="makeAdmin(user)">
              <v-tooltip bottom :disabled="$vuetify.breakpoint.xsOnly">
                <template #activator="{ on }">
                  <v-icon small v-on="on"> mdi-account-outline </v-icon>
                </template>
                {{ $t('team.makeAdmin') }}
              </v-tooltip>
            </v-btn>
          </v-chip>
        </template>
        <template #[`item.description`]="{ value }">
          <p class="ma-0" style="word-break: break-all">
            {{ value }}
          </p>
        </template>
        <template #[`item.created_at`]="{ value }">
          {{ $d(App.helpers.getDateObject(value), 'eventTime') }}
        </template>
        <template #no-data>{{ $t('team.emptyTeamList') }}</template>
        <template #[`item.team_update`]="{ item }">
          <div v-if="isUserAdmin(item)">
            <v-tooltip bottom :disabled="$vuetify.breakpoint.xsOnly">
              <template #activator="{ on }">
                <v-icon
                  class="ma-2"
                  v-on="on"
                  @click="openReorderTeamDialog(item)"
                >
                  mdi-sort
                </v-icon>
              </template>
              <span>{{ $i18n.t('team.changeUserOrder') }} </span>
            </v-tooltip>

            <v-tooltip bottom :disabled="$vuetify.breakpoint.xsOnly">
              <template #activator="{ on }">
                <v-icon class="ma-2" v-on="on" @click="editTeam(item)">
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $i18n.t('common.edit') }} </span>
            </v-tooltip>
            <v-tooltip bottom :disabled="$vuetify.breakpoint.xsOnly">
              <template #activator="{ on }">
                <v-icon class="ma-2" v-on="on" @click="deleteTeam(item)">
                  mdi-delete
                </v-icon>
              </template>
              <span>{{ $i18n.t('common.delete') }} </span>
            </v-tooltip>
          </div>
          <div v-else>
            <v-tooltip bottom :disabled="$vuetify.breakpoint.xsOnly">
              <template #activator="{ on }">
                <v-icon class="mr-2" v-on="on"> mdi-cancel </v-icon>
              </template>
              <span>{{ $i18n.t('team.cannotUpdateTeam') }} </span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <confirm ref="confirm" />
    <AddEditTeamModal ref="addEditTeamModal" @update="initialize" />
    <sorting-members
      :is-open="reorderDialog"
      :initial-selection="selectedTeam"
      :is-selector-disabled="true"
      @close="reorderDialog = false"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import AddEditTeamModal from '../components/AddEditTeamModal.vue';
import Confirm from '../components/Confirm.vue';
import { sendGoogleAnalyticsEvent } from '../plugins/vue-gtag';
import SortingMembers from './AdminSettings/components/SortingMembers.vue';

export default {
  components: {
    AddEditTeamModal,
    Confirm,
    SortingMembers,
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('emptySelectedUsers');
    next();
  },
  data: () => ({
    dialog: false,
    loading: false,
    teams: [],
    reorderDialog: false,
    selectedTeam: null,
    expanded: [],
  }),
  computed: {
    ...mapGetters(['id']),
    ...mapActions(['openSnackbar']),
    headers() {
      return [
        {
          text: this.$t('team.name'),
          align: 'start',
          sortable: false,
          value: 'name',
          width: '10%',
        },
        {
          text: this.$t('team.members'),
          align: 'start',
          sortable: false,
          value: 'users',
          width: '40%',
          isSelectable: false,
        },
        {
          text: this.$t('team.description'),
          align: 'start',
          sortable: false,
          width: '20%',
          value: 'description',
        },
        {
          text: this.$t('team.createdAt'),
          align: 'start',
          value: 'created_at',
          width: '10%',
          sortable: false,
        },
        {
          text: this.$t('common.actions'),
          align: 'start',
          sortable: false,
          width: '20%',
          value: 'team_update',
        },
      ];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.loading = true;
      axios
        .get(`api/user/teams/`)
        .then((response) => {
          this.teams = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    editTeam(team) {
      this.$refs.addEditTeamModal.openForEdit(team);
    },
    async deleteTeam(team) {
      sendGoogleAnalyticsEvent('teamAdminClickedOnDeleteTeamIcon');
      const confirmation = await this.$refs.confirm.open(
        this.$t('team.delete'),
        this.$t('team.deleteBody'),
      );
      if (confirmation === true) {
        axios
          .delete(`api/teams/${team.id}`)
          .then(() => {
            this.initialize();
            this.$store.dispatch('openSnackbar', {
              color: 'success',
              message: this.$t('team.deleted'),
            });
            this.$store.dispatch('groups/fetchGroups');
            sendGoogleAnalyticsEvent('teamAdminHasDeletedTeam', team.id);
          })
          .catch((error) => {
            if (error.response.status === 403) {
              this.$store.dispatch('openSnackbar', {
                color: 'error',
                message: this.$t('common.unauthorized'),
              });
              return;
            }
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t('common.somethingWentWrong'),
            });
          });
      }
    },
    openAddTeamDialog() {
      sendGoogleAnalyticsEvent('userClickedOnAddTeamButton');
      this.$refs.addEditTeamModal.openDialog();
    },
    makeAdmin(user) {
      const team = user.pivot.team_id;
      axios
        .patch(`api/team/${team}/admins/make/user/${user.id}`)
        .then((response) => {
          this.$store.dispatch('openSnackbar', {
            color: 'success',
            message: this.$t('team.adminAdded'),
          });
          this.$set(
            this.teams,
            this.teams.findIndex((team) => team.id === response.data.id),
            response.data,
          );
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t('common.unauthorized'),
            });
            return;
          }
          this.$store.dispatch('openSnackbar', {
            color: 'error',
            message: this.$t('common.somethingWentWrong'),
          });
        });
    },
    async removeAdmin(user) {
      if (user.id === this.id) {
        const confirmation = await this.$refs.confirm.open(
          this.$t('team.confirmRemoveAdmin'),
          this.$t('team.confirmRemoveAdminBody'),
        );
        if (confirmation) {
          this.sendRequestToRemoveAsAdmin(user);
        }
        return;
      }
      this.sendRequestToRemoveAsAdmin(user);
    },
    sendRequestToRemoveAsAdmin(user) {
      const team = user.pivot.team_id;
      axios
        .patch(`api/team/${team}/admins/remove/user/${user.id}`)
        .then((response) => {
          this.$store.dispatch('openSnackbar', {
            color: 'success',
            message: this.$t('team.adminRemoved'),
          });
          if (response.data) {
            this.$set(
              this.teams,
              this.teams.findIndex((team) => team.id === response.data.id),
              response.data,
            );
          } else {
            this.initialize();
          }
          sendGoogleAnalyticsEvent('userRemovedFromTeam', team);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.$store.dispatch('openSnackbar', {
              color: 'error',
              message: this.$t('common.unauthorized'),
            });
            return;
          }
          this.$store.dispatch('openSnackbar', {
            color: 'error',
            message: this.$t('common.somethingWentWrong'),
          });
        });
    },
    openReorderTeamDialog(item) {
      this.selectedTeam = item;
      this.selectedTeam.type = 'team';
      this.reorderDialog = true;
    },
    isUserAdmin(team) {
      let isAdmin = false;
      if (team.pivot && team.pivot.is_admin) {
        return true;
      }
      if (team.admins) {
        team.admins.forEach((admin) => {
          if (admin.id === this.id) {
            isAdmin = true;
          }
        });
      }
      return isAdmin;
    },
  },
};
</script>
