<template>
  <v-autocomplete
    v-model="department"
    hide-details
    :items="choices"
    :placeholder="$t('deptTeamSelector.selectDepartment')"
    :menu-props="{ auto: true }"
    dense
    item-text="name"
    return-object
    outlined
    @change="sendData"
  >
  </v-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      department: {},
    };
  },
  computed: {
    ...mapGetters('groups', ['departments']),
    choices() {
      const choices = [];
      choices.push({ divider: true });
      choices.push({
        header: this.$t('departmentTeamSelectionModal.departments'),
      });
      choices.push({ divider: true });
      const departments = this.departments.filter(
        (department) => department.id !== null,
      );
      return choices.concat(departments);
    },
  },
  methods: {
    sendData(data) {
      this.$emit('change', data);
    },
    resetDepartment() {
      this.department = {};
    },
  },
};
</script>
