<template>
  <v-container style="max-width: 1300px">
    <v-row class="px-4 mt-0 pb-4 headline">
      {{ $t('holidays.management') }}
      <v-spacer />
      <v-btn color="primary" @click="openAddHolidayModal">
        <v-icon left>mdi-plus</v-icon>
        {{ $t('holidays.add') }}
      </v-btn>
      <v-menu>
        <template #activator="{ on, attrs }">
          <v-btn class="ml-4" icon v-bind="attrs" v-on="on">
            <v-icon> mdi-dots-vertical </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="importPublicHolidays">
            <v-icon class="pr-3">mdi-import</v-icon>
            <v-list-item-title>
              {{ $t('holidays.importPublicHolidays') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items-per-page="25"
              :loading="isLoadingNationalHolidays || isImportingFromPublicApi"
              :items="nationalHolidaysToDisplay"
              :search="search"
              :footer-props="{
                itemsPerPageOptions: [10, 25, -1],
              }"
            >
              <template #[`item.created_at`]="{ value }">
                {{ $d(App.helpers.getDateObject(value), 'long') }}
              </template>
              <template #[`item.date`]="{ value }">
                {{ $d(App.helpers.getDateObject(value), 'allDayEvent') }}
              </template>
              <template #top>
                <v-row align="end">
                  <v-col cols="4" md="2" order="4" order-md="2">
                    <v-select
                      v-model="filterYear"
                      :items="filterOptions"
                      :label="$t('holidays.selectYear')"
                      menu-props="auto"
                      hide-details
                    />
                  </v-col>
                  <v-col cols="6" md="3" order="5" order-md="3">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      :label="$t('common.search')"
                      single-line
                      hide-details
                    />
                  </v-col>
                </v-row>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-tooltip bottom :disabled="$vuetify.breakpoint.xsOnly">
                  <template #activator="{ on }">
                    <v-icon class="mr-2" @click="editHoliday(item)" v-on="on">
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>{{ $t('common.edit') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <v-icon @click="deleteHoliday(item)" v-on="on">
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>{{ $t('common.delete') }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <AddEditHolidayModal
      ref="addEditHolidayModal"
      @added-holiday="fetchNationalHolidays"
      @edited-holiday="fetchNationalHolidays"
    />
    <Confirm ref="confirm" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AddEditHolidayModal from './AddEditHolidayModal.vue';
import Confirm from '../../components/Confirm.vue';

export default {
  components: {
    AddEditHolidayModal,
    Confirm,
  },
  data() {
    return {
      search: '',
      filterYear: new Date().getFullYear(),
      isImportingFromPublicApi: false,
    };
  },
  computed: {
    ...mapGetters([
      'nationalHolidays',
      'isLoadingNationalHolidays',
      'yearsWithNationalHolidays',
    ]),
    headers() {
      return [
        { text: this.$t('holidays.name'), value: 'name' },
        { text: this.$t('holidays.date'), value: 'date' },
        { text: this.$t('holidays.addedAt'), value: 'created_at' },
        { text: this.$t('common.actions'), value: 'actions', sortable: false },
      ];
    },
    filterOptions() {
      const array = [];
      array.push({ text: this.$t('common.all'), value: null });
      this.yearsWithNationalHolidays.forEach((year) => {
        array.push({ text: year, value: year });
      });
      return array;
    },
    nationalHolidaysToDisplay() {
      if (this.filterYear === null) {
        return this.nationalHolidays;
      }
      return this.nationalHolidays.filter((holiday) => {
        let { date } = holiday;
        date = App.helpers.getDateObject(date);
        if (this.filterYear === date.getFullYear()) {
          return true;
        }
        return false;
      });
    },
  },
  created() {
    this.fetchNationalHolidays();
  },
  methods: {
    ...mapActions(['fetchNationalHolidays']),
    openAddHolidayModal() {
      this.$refs.addEditHolidayModal.open();
    },
    editHoliday(holiday) {
      this.$refs.addEditHolidayModal.open(holiday, true);
    },
    async deleteHoliday(holiday) {
      const confirmation = await this.$refs.confirm.open(
        this.$t('common.confirmDelete'),
        this.$t('holidays.confirmDeleteBody', {
          holidayName: holiday.name,
          holidayDate: holiday.date,
        }),
        {
          color: 'red',
          width: 400,
        },
      );
      if (confirmation === true) {
        try {
          await axios.delete(`api/national-holidays/${holiday.id}`);
          this.$store.dispatch('openSnackbar', {
            message: this.$t('holidays.deleted'),
            color: 'success',
          });
          this.fetchNationalHolidays();
        } catch {
          this.$store.dispatch('openSnackbar', {
            message: this.$t('common.somethingWentWrong'),
            color: 'error',
          });
        }
      }
    },
    async importPublicHolidays() {
      try {
        this.isImportingFromPublicApi = true;
        await axios.get('api/national-holidays/import');
        this.$store.dispatch('openSnackbar', {
          message: this.$t('holidays.importedSuccess'),
          color: 'success',
        });
        this.isImportingFromPublicApi = false;
        this.fetchNationalHolidays();
      } catch {
        this.$store.dispatch('openSnackbar', {
          message: this.$t('common.somethingWentWrong'),
          color: 'error',
        });
      }
    },
  },
};
</script>
