<template>
  <v-dialog :value="dialog" persistent width="440px">
    <v-card>
      <v-card-title>
        {{ cardTitle }}
        <v-spacer />
        <v-btn icon @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="addEditEventType">
          <v-text-field
            v-model="eventType.name"
            dense
            outlined
            :label="$t('eventTypeCrud.eventTypeName')"
            :rules="[
              () => nameError() || $t('eventTypeCrud.eventTypeNameError'),
            ]"
          ></v-text-field>
          <event-color-picker v-model="eventType.color" />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" @click="checkValidity">
          {{ $t('common.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import EventColorPicker from './EventColorPicker.vue';

export default {
  components: {
    EventColorPicker,
  },
  props: {
    initialEventType: {
      type: Object,
      required: true,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      eventType: this.getDefaultEventType(),
    };
  },

  computed: {
    ...mapGetters({ eventTypes: 'eventTypesWithoutNull' }),
    cardTitle() {
      return this.initialEventType.id === 0
        ? this.$t('eventTypeCrud.addEventType')
        : this.$t('eventTypeCrud.editEventType');
    },
  },
  watch: {
    dialog() {
      if (this.dialog === true) {
        this.setDefaultData();
        this.setEventTypeSelected();
      }
    },
  },
  methods: {
    setDefaultData() {
      this.eventType = this.getDefaultEventType();
    },
    getDefaultEventType() {
      return {
        id: 0,
        name: '',
        color: 'primary',
      };
    },
    setEventTypeSelected() {
      this.eventType = { ...this.initialEventType };
    },
    checkValidity() {
      const validation = this.$refs.addEditEventType.validate();
      if (validation) {
        this.addEditEventType();
      }
    },
    async addEditEventType() {
      if (this.initialEventType.id === 0) {
        await axios.post('/api/event-types/', {
          name: this.eventType.name,
          color: this.eventType.color,
        });
        this.$store.dispatch('openSnackbar', {
          color: 'success',
          message: this.$t('eventTypeCrud.added'),
        });
      } else {
        await axios.put(`/api/event-types/${this.eventType.id}`, {
          name: this.eventType.name,
          color: this.eventType.color,
        });
        this.$store.dispatch('openSnackbar', {
          color: 'success',
          message: this.$t('eventTypeCrud.updated'),
        });
      }
      this.resetValidation();
      this.$emit('add-edit');
    },
    nameError() {
      if (this.eventType.name.trim() === '') {
        return false;
      }
      for (let i = 0; i < this.eventTypes.length; i += 1) {
        if (
          this.eventTypes[i].name === this.eventType.name.trim() &&
          this.eventTypes[i].id !== this.eventType.id
        ) {
          return false;
        }
      }
      return true;
    },
    async closeDialog() {
      await this.resetValidation();
      this.$emit('close');
    },
    resetValidation() {
      this.$refs.addEditEventType.resetValidation();
    },
  },
};
</script>
