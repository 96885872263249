<template>
  <v-dialog v-model="dialog" persistent width="500px">
    <v-card>
      <v-card-title>
        {{
          isEditMode ? $t('facilityTypeCrud.edit') : $t('facilityTypeCrud.add')
        }}
        <v-spacer />
        <v-btn icon color="grey" @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col>
              <v-text-field
                v-model="facilityType.name"
                :label="$t('facilityTypeCrud.facilityTypeName')"
                :rules="nameRules"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn outlined @click="dialog = false">
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn color="primary" :disabled="!valid" @click="save">
          {{ $t('common.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    dialog: false,
    valid: true,
    isEditMode: false,
    facilityType: {
      id: 0,
      name: '',
    },
    originalFacilityTypeData: {},
  }),
  computed: {
    ...mapGetters(['allFacilityTypes']),
    nameRules() {
      return [
        (v) => (!!v && v.trim() !== '') || this.$t('common.required'),
        (v) =>
          (v && this.isUniqueName(v)) ||
          this.$t('facilityTypeCrud.facilityTypeNameError'),
      ];
    },
  },
  methods: {
    openAddForm() {
      this.resetForm();
      this.isEditMode = false;
      this.dialog = true;
    },
    openEditForm(facilityType) {
      this.resetForm();
      this.isEditMode = true;
      this.dialog = true;
      this.facilityType = { ...facilityType };
      this.originalFacilityTypeData = { ...facilityType };
    },
    isUniqueName(name) {
      const allFacilityTypes = [...this.allFacilityTypes];
      if (this.isEditMode) {
        const index = allFacilityTypes.findIndex(
          (facilityType) =>
            facilityType.name === this.originalFacilityTypeData.name,
        );
        allFacilityTypes.splice(index, 1);
      }
      const isSameNameFacilityType = allFacilityTypes.some(
        (facilityType) => facilityType.name === name,
      );
      return !isSameNameFacilityType;
    },
    save() {
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.isEditMode) {
        this.editFacilityType();
        return;
      }
      this.addFacilityType();
    },
    async addFacilityType() {
      try {
        await axios.post('/api/admin/facility-types', this.facilityType);
        this.$store.dispatch('openSnackbar', {
          color: 'success',
          message: this.$t('facilityTypeCrud.added'),
        });
        this.$store.dispatch('fetchAndSetFacilityTypes');
      } catch {
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('common.somethingWentWrong'),
        });
      } finally {
        this.dialog = false;
      }
    },
    async editFacilityType() {
      try {
        await axios.patch(`/api/admin/facility-types/${this.facilityType.id}`, {
          name: this.facilityType.name,
        });
        this.$store.dispatch('openSnackbar', {
          color: 'success',
          message: this.$t('facilityTypeCrud.edited'),
        });
        this.$store.dispatch('fetchAndSetFacilityTypes');
      } catch {
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('common.somethingWentWrong'),
        });
      } finally {
        this.dialog = false;
      }
    },
    resetForm() {
      this.facilityType = {
        id: 0,
        name: '',
      };
      this.$nextTick(() => {
        this.$refs.form.resetValidation();
      });
    },
  },
};
</script>
