<template>
  <v-container style="max-width: 800px">
    <v-row class="px-4 mt-0 pb-5 headline">
      {{ $t('facilityTypeCrud.pageTitle') }}
      <v-spacer />
      <v-btn
        color="primary"
        elevation="0"
        @click="$refs.addEditFacility.openAddForm()"
      >
        <v-icon>mdi-plus</v-icon>
        {{ $t('facilityTypeCrud.add') }}
      </v-btn>
    </v-row>
    <v-row>
      <v-card width="100%">
        <v-card-text>
          <v-data-table :headers="headers" :items="allFacilityTypes">
            <template #[`item.count`]="{ item }">
              <div>{{ numOfFacilitiesWithType(item) }}</div>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-row>
                <v-col sm="1" class="mr-5">
                  <v-icon @click="$refs.addEditFacility.openEditForm(item)">
                    mdi-pencil
                  </v-icon>
                </v-col>
                <v-col sm="1">
                  <v-icon @click="handleDelete(item)"> mdi-delete </v-icon>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-row>
    <confirm ref="confirm" />
    <add-edit-facility-type ref="addEditFacility" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

import Confirm from '../../components/Confirm.vue';
import AddEditFacilityType from './components/AddEditFacilityType.vue';

export default {
  components: {
    Confirm,
    AddEditFacilityType,
  },
  computed: {
    ...mapGetters(['allFacilityTypes', 'allFacilities']),
    headers() {
      return [
        {
          text: this.$t('facilityTypeCrud.facilityType'),
          value: 'name',
          width: '50%',
          sortable: true,
        },
        {
          text: this.$t('facilityTypeCrud.numOfFacilities'),
          value: 'count',
          width: '30%',
          sortable: true,
        },
        {
          text: this.$t('common.actions'),
          value: 'actions',
          width: '20%',
          sortable: false,
        },
      ];
    },
  },
  methods: {
    handleDelete(facilityType) {
      if (this.isDeleteNotPossible(facilityType)) {
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('facilityTypeCrud.cannotDelete', {
            name: facilityType.name,
            count: this.numOfFacilitiesWithType(facilityType),
          }),
          timeout: 15000,
        });
        return;
      }
      this.confirmDelete(facilityType);
    },
    isDeleteNotPossible(facilityType) {
      return this.allFacilities.some(
        (facility) => facility.typeName === facilityType.name,
      );
    },
    numOfFacilitiesWithType(facilityType) {
      return this.allFacilities.reduce((accumulator, facility) => {
        if (facility.typeName === facilityType.name) {
          return accumulator + 1;
        }
        return accumulator;
      }, 0);
    },
    async confirmDelete(facilityType) {
      const isConfirmed = await this.$refs.confirm.open(
        this.$t('common.confirmDelete'),
        this.$t('facilityTypeCrud.deleteConfirmBody', {
          name: facilityType.name,
        }),
        { color: 'red' },
      );
      if (isConfirmed) {
        this.deleteFacilityType(facilityType.id);
      }
    },
    async deleteFacilityType(id) {
      try {
        await axios.delete(`/api/admin/facility-types/${id}`);
        this.$store.dispatch('openSnackbar', {
          color: 'success',
          message: this.$t('facilityTypeCrud.deleted'),
        });
        this.$store.dispatch('fetchAndSetFacilityTypes');
      } catch {
        this.$store.dispatch('openSnackbar', {
          color: 'error',
          message: this.$t('common.somethingWentWrong'),
        });
      }
    },
  },
};
</script>
