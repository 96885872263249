<template>
  <v-container>
    <v-row class="headline">
      <v-col>
        {{ $t('adminSettings.adminSettings') }}
      </v-col>
    </v-row>
    <v-card>
      <v-list class="py-0">
        <v-list-group>
          <template #activator>
            <v-list-item-icon>
              <v-icon>mdi-sort</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t('adminSettings.sorting') }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-for="(sortingItem, i) in sortingItems">
            <v-list-item :key="i" @click="handleNavigation(sortingItem.id)">
              <v-list-item-icon class="pl-12">
                <v-icon>{{ sortingItem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  v-text="sortingItem.text"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
        <v-divider />
        <template v-for="(item, i) in items">
          <v-list-item :key="i" @click="handleNavigation(item.id)">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="i < items.length - 1" :key="'div' + i" />
        </template>
      </v-list>
    </v-card>
    <sorting-members
      :is-open="isSortingMembersDialogOpen"
      @close="isSortingMembersDialogOpen = false"
    />
    <sorting-departments
      :is-open="isSortingDepartmentsDialogOpen"
      @close="isSortingDepartmentsDialogOpen = false"
    />
    <sorting-facilities
      :is-open="isSortingFacilitiesDialogOpen"
      @close="isSortingFacilitiesDialogOpen = false"
    />
  </v-container>
</template>

<script>
import SortingMembers from './components/SortingMembers.vue';
import SortingDepartments from './components/SortingDepartments.vue';
import SortingFacilities from './components/SortingFacilities.vue';

export default {
  components: {
    SortingMembers,
    SortingDepartments,
    SortingFacilities,
  },
  data: () => ({
    isSortingMembersDialogOpen: false,
    isSortingDepartmentsDialogOpen: false,
    isSortingFacilitiesDialogOpen: false,
  }),
  computed: {
    items() {
      return [
        {
          icon: 'mdi-calendar-star',
          text: this.$t('adminSettings.manageEventTypes'),
          id: 'manage-event-types',
        },
        {
          icon: 'mdi-seat',
          text: this.$t('adminSettings.manageFacilities'),
          id: 'manage-facilities',
        },
        {
          icon: 'mdi-bullhorn',
          text: this.$t('adminSettings.departmentSendingInformation'),
          id: 'information-send-permissions',
        },
        {
          icon: 'mdi-calendar',
          text: this.$t('adminSettings.manageHolidays'),
          id: 'manage-holidays',
        },
        {
          icon: 'mdi-application-cog',
          text: this.$t('adminSettings.manageCalendarSettings'),
          id: 'manage-calendar-settings',
        },
      ];
    },
    sortingItems() {
      return [
        {
          icon: 'mdi-account',
          text: this.$t('adminSettings.sortEmployees'),
          id: 'sort-employees',
        },
        {
          icon: 'mdi-briefcase',
          text: this.$t('adminSettings.sortDepartments'),
          id: 'sort-departments',
        },
        {
          icon: 'mdi-seat',
          text: this.$t('adminSettings.sortFacilities'),
          id: 'sort-facilities',
        },
      ];
    },
  },
  methods: {
    handleNavigation(id) {
      switch (id) {
        case 'sort-employees':
          this.isSortingMembersDialogOpen = true;
          break;
        case 'sort-departments':
          this.isSortingDepartmentsDialogOpen = true;
          break;
        case 'sort-facilities':
          this.isSortingFacilitiesDialogOpen = true;
          break;
        case 'manage-event-types':
          this.$router.push({ name: 'event-type-settings' });
          break;
        case 'manage-facilities':
          this.$router.push({ name: 'facility-settings' });
          break;
        case 'information-send-permissions':
          this.$router.push({ name: 'department-sending-information' });
          break;
        case 'manage-holidays':
          this.$router.push({ name: 'national-holidays' });
          break;
        case 'manage-calendar-settings':
          this.$router.push({ name: 'calendar-settings' });
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 700px;
}
</style>
