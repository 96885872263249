<template>
  <v-card elevation="0">
    <v-card-title>
      <v-checkbox
        v-model="decision"
        :label="$t('facilityCrud.facilityExtraInfo')"
      />
      <v-spacer />
      <v-btn
        v-if="decision == true"
        elevation="0"
        color="green lighten-3"
        fab
        x-small
        @click="addCategoryRow"
      >
        <v-icon color="white"> mdi-plus </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="py-0">
      <details-tabs
        v-if="decision == true"
        ref="detailsTabs"
        :editing-extra-info="extraInfo"
        @changed="sendFacilityExtraInfo"
      >
      </details-tabs>
    </v-card-text>
  </v-card>
</template>

<script>
import DetailsTabs from './DetailsTabs.vue';

export default {
  components: {
    DetailsTabs,
  },

  props: {
    editingExtraInfo: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    decision: false,

    extraInfo: [],
  }),

  watch: {
    editingExtraInfo() {
      this.setExtraInfoData();
    },
  },

  created() {
    this.getDefaultData();
  },

  methods: {
    getDefaultData() {
      this.decision = false;
      this.extraInfo = [];
      this.setExtraInfoData();
    },

    setExtraInfoData() {
      this.extraInfo = this.editingExtraInfo;
      if (this.extraInfo.length > 0) {
        this.decision = true;
      } else {
        this.decision = false;
      }
    },

    addCategoryRow() {
      this.$refs.detailsTabs.addCategoryRow();
    },

    sendFacilityExtraInfo(extraInfos) {
      this.$emit('change', extraInfos);
    },
  },
};
</script>
